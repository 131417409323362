import './App.css';
import React, {Suspense, useEffect} from "react";
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import EventIcon from '@material-ui/icons/Event';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import {useAuth0} from "./auth0/react-auth0-spa";
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import UpdateIcon from "@material-ui/icons/Update";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import SettingsIcon from '@material-ui/icons/Settings';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import StorefrontIcon from '@material-ui/icons/Storefront';
import GetRestaurantInfoForBackOffice from "./graphql/getRestaurantInfoForBackOffice";
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Payments from "./screens/Payments";
import StoreIcon from '@material-ui/icons/Store';
import PhotoIcon from '@material-ui/icons/Photo';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import TextRotationNoneIcon from '@material-ui/icons/TextRotationNone';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ScheduleIcon from '@material-ui/icons/Schedule';

const CategoriesEat = React.lazy(() => import('./screens/CategoriesEat'));
const ItemsSit = React.lazy(() => import('./screens/ItemsSit'));
const ItemsEat = React.lazy(() => import('./screens/ItemsEat'));
const ManageClients = React.lazy(() => import('./screens/ManageClients'));
const ManageAdverts = React.lazy(() => import('./screens/ManageAdverts'));
const ManageStaticAdverts = React.lazy(() => import('./screens/ManageStaticAdverts'));
const ManageRunningText = React.lazy(() => import('./screens/ManageRunningText'));
const ManagePromoCode = React.lazy(() => import('./screens/ManagePromoCode'));
const ManageClosingTime = React.lazy(() => import('./screens/ManageClosingTime'));

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false};
		this.state = {error: ''};
		this.state = {openErrorDialog: true};
	}

	componentDidCatch(error, info) {
		console.log("IN COMPONENT DID CATCH")
		// Display fallback UI
		this.setState({hasError: true});
		this.setState({error: error});
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return(
				<Dialog
					open={this.state.openErrorDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
					<DialogContent>
						<DialogContentText  style={{whiteSpace: "pre-wrap"}} id="alert-dialog-description">{"An error has occurred: \n" +  this.state.error + "\nPlease contact the system administrators if this keeps happening."}</DialogContentText>
					</DialogContent>
				</Dialog>
			)
		}
		return this.props.children;
	}
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		overflow: "hidden",
		maxWidth: "100vw"
	},
}));

function usePersistedState(key, defaultValue) {
	const [state, setState] = React.useState(
		() => JSON.parse(localStorage.getItem(key)) || defaultValue
	);
	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(state));
	}, [key, state]);
	return [state, setState];
}

function Admin() {
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const {user, logout} = useAuth0();

	const isAdmin = user["https://sitandorder.mt/roles"].includes("Admin")
	const isAdvertiser = user["https://sitandorder.mt/roles"].includes("<ADVERTISER>")
	let defaultVal = 'Manage Categories'
	if(isAdmin){
		defaultVal = "Manage Clients"
	}
	if(isAdvertiser){
		defaultVal = "Payments"
	}


	const [component, setComponent] = usePersistedState('component', defaultVal)
	let role;

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const [openErrorDialog, setErrorDialogOpen] = React.useState(true);

	const [error, setError] = React.useState('');

	let restaurant_data;
	let hasTrial;
	let trialDaysLeft;
	try {
		console.log(isAdvertiser)
		if(!isAdvertiser) {
			console.log(isAdvertiser)
			restaurant_data = GetRestaurantInfoForBackOffice(" ")

			console.log(restaurant_data)

			if (restaurant_data !== 'LOADING') {
				if (restaurant_data[0].eatForLess) {
					role = "Eat For Less"
				} else {
					role = "Sit & Order"
				}

				if (restaurant_data[0].hasTrial) {
					hasTrial = true;

					var date = new Date(restaurant_data[0].endOfTrial);
					var currDate = new Date();
					console.log(date)

					const one_day = 1000 * 60 * 60 * 24;
					let result;
					result = Math.ceil((date.getTime() - currDate.getTime()) / (one_day));
					console.log('date Converter result', result);
					trialDaysLeft = result
				}
			} else {
				hasTrial = false;
			}
		}

	} catch (e) {
		restaurant_data = []
		if (error === '') {
			setError("The following error has occurred: \n\n" + e.message + "\n\nPlease contact the system administrators if this keeps happening.")
		}
	}

	const drawer = (
		<div>
			<div className={classes.toolbar}/>
			<Divider/>
			<List>

				{isAdmin &&
				<>
					<ListItem button selected={component === 'Manage Clients'} onClick={() => {
						setComponent('Manage Clients')
						if(mobileOpen) {
							handleDrawerToggle()
						}
					}}
							  key={'Manage Clients'}>
						<ListItemIcon>
							<StoreIcon/>
						</ListItemIcon>
						<ListItemText primary={'Manage Clients'}/>
					</ListItem>
				</>
				}

				{isAdmin &&
				<>
					<ListItem button selected={component === 'Manage Adverts'} onClick={() => {
						setComponent('Manage Adverts')
						if(mobileOpen) {
							handleDrawerToggle()
						}
					}}
							  key={'Manage Adverts'}>
						<ListItemIcon>
							<PhotoIcon/>
						</ListItemIcon>
						<ListItemText primary={'Image Adverts'}/>
					</ListItem>
				</>
				}

				{isAdmin &&
				<>
					<ListItem button selected={component === 'Manage Static Adverts'} onClick={() => {
						setComponent('Manage Static Adverts')
						if(mobileOpen) {
							handleDrawerToggle()
						}
					}}
							  key={'Manage Static Adverts'}>
						<ListItemIcon>
							<TextFieldsIcon/>
						</ListItemIcon>
						<ListItemText primary={'Static Adverts'}/>
					</ListItem>
				</>
				}

				{isAdmin &&
				<>
					<ListItem button selected={component === 'Manage Running Text'} onClick={() => {
						setComponent('Manage Running Text')
						if(mobileOpen) {
							handleDrawerToggle()
						}
					}}
							  key={'Manage Running Text'}>
						<ListItemIcon>
							<TextRotationNoneIcon/>
						</ListItemIcon>
						<ListItemText primary={'Running Text'}/>
					</ListItem>
					<Divider/>
				</>
				}

				{!isAdmin && !isAdvertiser && ((role === 'Sit & Order') || (role === 'Eat For Less')) &&
				<ListItem button selected={component === 'Manage Categories'} onClick={() => {setComponent('Manage Categories')
					if(mobileOpen) {
						handleDrawerToggle()
					}}}
						  key={'Manage Categories'}>
					<ListItemIcon>
						<MenuBookIcon/>
					</ListItemIcon>
					<ListItemText primary={'Manage Categories'}/>
				</ListItem>
				}

				{!isAdmin && !isAdvertiser && ((role === 'Sit & Order') || (role === 'Eat For Less')) &&
				<>
					<ListItem button selected={component === 'Manage Items'} onClick={() => {setComponent('Manage Items')
						if(mobileOpen) {
							handleDrawerToggle()
						}}}
							  key={'Manage Items'}>
						<ListItemIcon>
							<FastfoodIcon/>
						</ListItemIcon>
						<ListItemText primary={'Manage Items'}/>
					</ListItem>

				</>
				}

				{!isAdmin && !isAdvertiser && ((role === 'Sit & Order') || (role === 'Eat For Less')) &&
				<ListItem button selected={component === 'Closing Time'} onClick={() => {setComponent('Closing Time')
					if(mobileOpen) {
						handleDrawerToggle()
					}}}
						  key={'Closing Time'}>
					<ListItemIcon>
						<ScheduleIcon/>
					</ListItemIcon>
					<ListItemText primary={'Closing Time'}/>
				</ListItem>
				}

				{!isAdmin && !isAdvertiser && ((role === 'Sit & Order') || (role === 'Eat For Less')) &&
				<ListItem button selected={component === 'Promo Code'} onClick={() => {setComponent('Promo Code')
					if(mobileOpen) {
						handleDrawerToggle()
					}}}
						  key={'Promo Code'}>
					<ListItemIcon>
						<LocalAtmIcon/>
					</ListItemIcon>
					<ListItemText primary={'Promo Code'}/>
					<Divider/>
				</ListItem>
				}

				{!isAdmin && !isAdvertiser && role === 'Sit & Order' &&
				<ListItem button selected={component === 'Manage Tables'} onClick={() => {setComponent('Manage Tables')
					if(mobileOpen) {
						handleDrawerToggle()
					}}}
						  key={'Manage Tables'}>
					<ListItemIcon>
						<StorefrontIcon/>
					</ListItemIcon>
					<ListItemText primary={'Manage Tables'}/>
				</ListItem>
				}

				{!isAdmin && !isAdvertiser && role === 'Sit & Order' &&
					<ListItem button selected={component === 'Tables'} onClick={() => {setComponent('Tables')
						if(mobileOpen) {
							handleDrawerToggle()
						}}}
							  key={'Tables'}>
						<ListItemIcon>
							<RestaurantIcon/>
						</ListItemIcon>
						<ListItemText primary={'Live Tables'}/>
					</ListItem>
				}

				{!isAdmin && !isAdvertiser && role === 'Sit & Order' &&
				<>
					<ListItem button selected={component === 'Live Orders'}
							  onClick={() => {setComponent('Orders')
								  if(mobileOpen) {
									  handleDrawerToggle()
								  }}} key={'Orders'}>
						<ListItemIcon>
							<RoomServiceIcon/>
						</ListItemIcon>
						<ListItemText primary={'Live Orders'}/>
					</ListItem>
					<Divider/>
				</>
				}

				{!isAdmin && ((role === 'Sit & Order') || (role === 'Eat For Less') || isAdvertiser) &&
				<ListItem button selected={component === 'Payments'} onClick={() => {setComponent('Payments')
					if(mobileOpen) {
						handleDrawerToggle()
					}}}
				          key={'Payments'}>
					<ListItemIcon>
						<CreditCardIcon/>
					</ListItemIcon>
					<ListItemText primary={'Payments'}/>
				</ListItem>
				}

				<ListItem button selected={component === 'Log Out'} onClick={() => {
					logout({returnTo: window.location.origin})
				}} key={'Log Out'}>
					<ListItemIcon>
						<ExitToAppIcon/>
					</ListItemIcon>
					<ListItemText primary={'Log Out'}/>
				</ListItem>

			</List>
		</div>
	);

	return (
		<ErrorBoundary>
		<div className={classes.root}>
			<CssBaseline/>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						className={classes.menuButton}
					>
						<MenuIcon/>
					</IconButton>
					<Typography variant="h6" noWrap>
						{component}
					</Typography>
				</Toolbar>
			</AppBar>
			<nav className={classes.drawer} aria-label="mailbox folders">
				<Hidden smUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
			{!isAdmin && hasTrial &&
				<Paper sx={{ zIndex: "1500", position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: "#ff7f7f" }} elevation={2}>
					{trialDaysLeft >= 0 ?
						<h3 style={{margin: "8px"}}>Trial Version: {trialDaysLeft} day(s) left</h3>
					:
						<h3 style={{margin: "8px"}}>Your trial version has expired</h3>
					}
				</Paper>
			}
			<main className={classes.content}>
				<div className={classes.toolbar}/>

				{component === 'Manage Clients' &&
				<Suspense fallback={<></>}>
					<ManageClients/>
				</Suspense>
				}

				{component === 'Manage Adverts' &&
				<Suspense fallback={<></>}>
					<ManageAdverts/>
				</Suspense>
				}

				{component === 'Manage Static Adverts' &&
				<Suspense fallback={<></>}>
					<ManageStaticAdverts/>
				</Suspense>
				}

				{component === 'Manage Running Text' &&
				<Suspense fallback={<></>}>
					<ManageRunningText/>
				</Suspense>
				}

				{component === 'Manage Categories' &&
				<Suspense fallback={<></>}>
					<CategoriesEat/>
				</Suspense>
				}

				{component === 'Manage Items' && role === "Eat For Less" &&
				<Suspense fallback={<></>}>
					<ItemsEat/>
				</Suspense>
				}

				{component === 'Closing Time' && role === "Eat For Less" &&
				<Suspense fallback={<></>}>
					<ManageClosingTime/>
				</Suspense>
				}

				{component === 'Promo Code' && role === "Eat For Less" &&
				<Suspense fallback={<></>}>
					<ManagePromoCode/>
				</Suspense>
				}

				{component === 'Manage Items' && role === "Sit & Order" &&
				<Suspense fallback={<></>}>
					<ItemsSit/>
				</Suspense>
				}

				{component === 'Payments' &&
					<Suspense fallback={<></>}>
						<Payments/>
					</Suspense>
				}

				{component === 'Log Out' &&
				alert("Log out")
				}
			</main>
			{error !== '' &&
			<Dialog
				open={openErrorDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{error}</DialogContentText>
				</DialogContent>
			</Dialog>
			}
		</div>
		</ErrorBoundary>
	);
}

export default Admin;
