import React, {useState, useEffect} from 'react';
import GetRestaurantInfoForBackOffice from "../graphql/getRestaurantInfoForBackOffice";
import {useMutation} from "@apollo/react-hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import {CREATE_CHECKOUT_SESSION} from "../graphql/createCheckoutSession";
import {CREATE_PORTAL_SESSION} from "../graphql/createPortalSession";
import {CREATE_PORTAL_SESSION_FROM_CUSTOMER_ID} from "../graphql/createPortalSessionFromCustomerID";
import {ADD_STRIPE_CUSTOMER_ID} from "../graphql/addStripeCustomerID";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));


const ProductDisplay = ({pricePerMonth,error, setError}) => {
    const classes = useStyles();
    let [checkout, _] = useMutation(CREATE_CHECKOUT_SESSION)

    const [buttonDisabled, setButtonDisabled] = React.useState(false);
    return (
        <section>
            <div className="product">
                <div className="description">
                    <h1>Pay Subscription Fee</h1>
                    <p>Setup your subscription. Enter your details once and the system will automatically withdraw from your chosen payment method.</p>
                    <p>To cancel your subscription please contact Adrian on <b>+356 9901 7126</b> or <b>stockforsale@yahoo.com</b></p>
                    <h4>Price: €{pricePerMonth} / month</h4>
                </div>
            </div>
            <Button disabled={buttonDisabled} onClick={(e) => {
                e.preventDefault();
                setButtonDisabled(true)
                checkout().then(response => {
                    console.log(response)
                    window.location.href = response.data.createCheckoutSession;
                    // window.location.reload()
                })
                    .catch(e => {
                        if (error === '') {
                            setError("The following error has occurred: \n" + e.message + "\nPlease contact the system administrators if this keeps happening.")
                        }
                    })
            }} color="primary" autoFocus className={classes.button} variant="contained">
                Checkout
            </Button>
        </section>
    );
}

const SuccessDisplay = ({sessionId, error, setError}) => {

    const classes = useStyles();
    let [portal, _] = useMutation(CREATE_PORTAL_SESSION)

    const [buttonDisabled, setButtonDisabled] = React.useState(false);
    return (
        <section>
            <div className="product Box-root">
                <div className="description Box-root">
                    <h3>Subscription to starter plan successful!</h3>
                    <p>To cancel your subscription please contact Adrian on <b>+356 9901 7126</b> or <b>stockforsale@yahoo.com</b></p>

                </div>
            </div>
            <Button disabled={buttonDisabled} onClick={(e) => {
                e.preventDefault();
                setButtonDisabled(true)
                portal({
                    variables: {
                        session_id: sessionId
                    }
                }).then(response => {
                    console.log(response)
                    window.location.href = response.data.createPortalSession;
                    // window.location.reload()
                })
                    .catch(e => {
                        if (error === '') {
                            setError("The following error has occurred: \n" + e.message + "\nPlease contact the system administrators if this keeps happening.")
                        }
                    })
            }} color="primary" autoFocus className={classes.button} variant="contained">
                Manage Billing Information
            </Button>
        </section>
    );
};


const ShowPortal = ({customer,error, setError}) => {
    const classes = useStyles();
    let [portal, _] = useMutation(CREATE_PORTAL_SESSION_FROM_CUSTOMER_ID)

    const [buttonDisabled, setButtonDisabled] = React.useState(false);
    return (
        <section>
            <div className="product Box-root">
                <div className="description Box-root">
                    <h3>Subscription already set up.</h3>
                    <p>To cancel your subscription please contact Adrian on <b>+356 9901 7126</b> or <b>stockforsale@yahoo.com</b></p>

                </div>
            </div>
            <Button disabled={buttonDisabled} variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={(e) => {
                e.preventDefault();
                setButtonDisabled(true)
                portal({
                    variables: {
                        customer
                    }
                }).then(response => {
                    console.log(response)
                    window.location.href = response.data.createPortalSessionFromCustomerID;
                    // window.location.reload()
                })
                    .catch(e => {
                        if (error === '') {
                            setError("The following error has occurred: \n" + e.message + "\nPlease contact the system administrators if this keeps happening.")
                        }
                    })
            }} autoFocus>
                Manage Billing Information
            </Button>
        </section>
    );
};

export default function Payments() {
    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('');
    let [pricePerMonth, setPricePerMonth] = useState(0);
    let [customerID, setCustomerID] = useState(null);
    let restaurant_data = GetRestaurantInfoForBackOffice("2TW2zwZ71PlT8GKYROUx");
    let [add_id, _] = useMutation(ADD_STRIPE_CUSTOMER_ID)
    const [error, setError] = React.useState('');
    const [openErrorDialog, setErrorDialogOpen] = React.useState(true);


    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setSuccess(true);
            setSessionId(query.get('session_id'));
            add_id({
                variables: {
                    session: query.get('session_id')
                }
            }).then(response => {
                console.log(response)
            })
                .catch(e => {
                    console.error(e)
                    if (error === '') {
                        setError("The following error has occurred: \n" + e.message + "\nPlease contact the system administrators if this keeps happening.")
                    }
                })
        }

        if (query.get('canceled')) {
            setSuccess(false);
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, [sessionId]);

    useEffect(() => {
        if (restaurant_data !== 'LOADING') {
            console.log(restaurant_data[0])
            setPricePerMonth(restaurant_data[0].pricePerMonth)
            setCustomerID(restaurant_data[0].stripeCustomerID)
        }
    }, [restaurant_data]);
    if (restaurant_data === 'LOADING') {
        return (
            <LinearProgress style={{height: "7px"}}/>
        );
    } else {
        if (!success && message === '' && customerID == null) {
            return (<>
                <ProductDisplay pricePerMonth={pricePerMonth} error={error} setError={setError}/>
                {
                    error !== '' &&
                    <Dialog
                        open={openErrorDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description"
                                               style={{whiteSpace: "pre-wrap"}}>{error}</DialogContentText>
                        </DialogContent>
                    </Dialog>
                }
            </>);
        } else if (customerID != null) {
            return (<><ShowPortal customer={customerID} error={error} setError={setError}/>
                {
                    error !== '' &&
                    <Dialog
                        open={openErrorDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description"
                                               style={{whiteSpace: "pre-wrap"}}>{error}</DialogContentText>
                        </DialogContent>
                    </Dialog>
                }
            </>);
        } else if (success) {
            return (<>
                <SuccessDisplay sessionId={sessionId} error={error} setError={setError}/>
                {
                    error !== '' &&
                    <Dialog
                        open={openErrorDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description"
                                               style={{whiteSpace: "pre-wrap"}}>{error}</DialogContentText>
                        </DialogContent>
                    </Dialog>
                }
            </>);
        }
    }
}