import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const getRestaurantInfoForBackOffice = gql`
    query getRestaurantInfoForBackOffice($restaurantID: ID!)  {
        getRestaurantInfoForBackOffice(restaurantID: $restaurantID) {
            name
            email
            logo
            path
            map
            image
            locality
            hasTrial
            isActive
            actual_id
            categories{
              items{
                name
                image
                price
                actual_id
                ingredients{
                  name
                  actual_id
                }
                percentageOff
                short_description
                toShow
                  link_to_order
              }
              image
              actual_id
              category_name
              percentageOff
              
            }
            endOfTrial
            phone_number
            pricePerMonth
            eatForLess
            stripeCustomerID
            toHideAccordingToAdmin
            offersDelivery
            promo_code
            closing_time
        }
    }
`;

//Function we can call to get all the survey link
export default function GetRestaurantInfoForBackOffice(restaurantID) {

    //executing the query
    const {error, loading, data } = useQuery(getRestaurantInfoForBackOffice,{
        variables: {
            restaurantID
        }
    })

    if (loading)
        return "LOADING";
    if(error){
        console.log(error)
        throw new Error(error.message);
    }
    return data.getRestaurantInfoForBackOffice;
}
