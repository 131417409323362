import './App.css';
import { Router, Switch } from "react-router-dom";
import Profile from "./auth0/Profile";
import history from "./auth0/history";
import PrivateRoute from "./auth0/PrivateRoute";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

function App() {
  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="App">
            {/* Don't forget to include the history module */}
            <Router history={history}>
              <Switch>
                <PrivateRoute path="/" component={Profile} />
              </Switch>
            </Router>
          </div>
      </MuiPickersUtilsProvider>
  );
}

export default App;
